$breakpoint-phone: 640px
$breakpoint-tablet: 768px
$breakpoint-laptop: 1024px
$breakpoint-desktop: 1280px
$breakpoint-widescreen: 1536px

//300 light, 400 normal, 500 medium, 600 semibold, 700 bold
.font-signika
  font-family: "Signika", sans-serif
  font-optical-sizing: auto
  font-style: normal
  font-variation-settings: "GRAD" 0

html, body
  margin: 0
  overflow-x: hidden

h1, h2, h3, h4
  font-family: "Signika"

p, ul
  font-size: 1.125rem //text-base
  line-height:1.75rem //text-base
  margin-bottom: 1.25rem //mb-5

.content p:last-child, .content ul:last-child
  margin-bottom: 0

a
  font-weight:600
  color: deepskyblue

section
    position: relative
    width: 100%
    align-items: center
    justify-content: center
    color: white
    font-size: 2rem
    padding-left:1.125rem
    padding-right:1.125rem

@media (width >= $breakpoint-tablet)
  section
    padding-left:2.25rem
    padding-right:2.25rem


.content
    z-index: 2
    position: relative

.parallax
    background-attachment: fixed
    // filter: brightness(0.8)
    will-change: background-position

#parallax_bg_1
    background-image: url('/images/san_ti_vertical_cropped.webp')

@media (width >= $breakpoint-tablet)
    #parallax_bg_1
        background-image: url('/images/bastons_b&w_horizontal.webp')

#parallax_bg_2
    background-image: url('/images/bastons_02_b&w_cropped_gradients.webp') 
    height:45vh
      
@media (width >= $breakpoint-tablet)
  #parallax_bg_2
      height:55vh